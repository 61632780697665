let Organization = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
    * 查询组织机构详情
    * @param {any} organizationId
    * @param {any} success
    * @param {any} error
    */
    this.Detial = function (organizationId, success, error) {
      var url = ServiceBaseAddress + '/api/Organization/' + organizationId;
      TokenClient.Get(url, true, null, null,
          function (data) {
              if (success) {
                  var jsonObj = JSON.parse(data);
                  success(jsonObj);
              }
          }, error);
    }
    /**
     * 查询科室
     * @param {any} organizationId
     * @param {any} success
     * @param {any} error
     */
    this.OrgDepartment = function (organizationId, success, error) {
      var url = ServiceBaseAddress + '/api/Department/Organization/';
      if (organizationId) {
          url += organizationId;
      }
      else {
          url += 0;
      }
      TokenClient.Get(url, true, null, null,
          function (data) {
              if (success) {
                  var jsonObj = JSON.parse(data);
                  success(jsonObj);
              }
          }, error);
    }
    /**
     * 查询科室
     * @param {any} organizationId
     * @param {any} success
     * @param {any} error
     */
    this.Department = function (organizationId, success, error) {
      var url = ServiceBaseAddress + '/api/Department/Organization/Tree/';
      if (organizationId) {
          url += organizationId;
      }
      else {
          url += 0;
      }
      TokenClient.Get(url, true, null, null,
          function (data) {
              if (success) {
                  var jsonObj = JSON.parse(data);
                  success(jsonObj);
              }
          }, error);
    }
    /**
     * 查询指定组织机构信息，以树形结构返回上下级信息
     * @param {any} organizationId
     * @param {any} success
     * @param {any} error
     */
    this.RefeHospital = function (success, error) {
        var url = ServiceBaseAddress + '/api/Organization/Parents';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.RotaHospital = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Organization/Childrens/' + organizationId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 根据分类查询组织机构
     * @param {any} organizationId
     * @param {any} success
     * @param {any} error
     */
    this.Hospital = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Organization/Category/' + organizationId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.getAllHospital = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Organization/ProductKey/YunHIS';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    
}

export { Organization }
